
/**
 * Pin indictator
 */

.pin-indicator {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 25%;
  height: .5em;
  transform: translate(-50%, -50%);
  background: black;
  z-index:999999;
  opacity: 0;
  &.active {
    opacity: 1;
  }
}


