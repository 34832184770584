/**
 * Front page panel
 */
.masthead {
  text-align: center;
  position:fixed;
  width: 100%;
  height: 100vh;
  z-index: 3;
}

.masthead {
  > div {
    height: 100%;
  }

  .masthead-title {
    width: 100%;
    text-align: center;
    font-size: 8vw;
    font-weight: bold;
    line-height: 1.1;
    position: absolute;
    margin: 0;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
