/**
 * Navigation
 */

footer.main-navigation {
  transition: bottom 1s;
}

.navigation {
  z-index: 4000;
  user-select: none;
}

.navigation {
  white-space: nowrap;
  text-overflow: ellipsis;

  // overflow: hidden;
  // overflow: visible;
  overflow: hidden;
}

.menu {
  position:absolute;
  // left: ($grid-gutter-width / 2);
  left: 50%;
  transform: translateX(-50%);
}

// https://www.smashingmagazine.com/2012/02/finger-friendly-design-ideal-mobile-touchscreen-target-sizes/
// http://front-back.com/expand-clickable-areas-for-a-better-touch-experience
.main-navigation li a {
  /* lift, to allow :before be injected below and to use this as position reference */
  position: relative;
  &:before {
    content: "";

    /* tuch area increase */
    left: -1em;
    right: -1em;
    bottom: -1em;
    top: -1em;

    /* position below button */
    position: absolute;
    z-index: -1;
  }
}

.navbar {
  padding: .75rem 0;
}

.navbar-fixed {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 5;
}


.close {
  animation: flash 4s infinite ease;
  animation-fill-mode: forwards;
  &:hover {
    animation: none;
    // animation-play-state: paused;
    animation-fill-mode: forwards;
    // opacity: 1!important;
  }
}

.main-navigation {
  height: 3rem;
  transition: background-color .125s;
}

// Always white
.main-navigation {
  background-color: white;
}

.main-navigation:hover {
  background-color: white;
}

.main-navigation {

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    text-align: center;
    li {
      display: inline-block;
      // float: left;
      margin: 0 auto;
    }
  }

  .menu {
    width: 100%;
    top: 0;
    li {
      padding: 0 .5em;
    }
  }
}

.menu-toggle {
  position: relative;
  li {
    // position: absolute;
    // left: 0;
  }
}

.menu, .menu-toggle {
  // transition: opacity .25s ease;
}

.menu-toggle {
  z-index: -1;
}

.menu-toggle {
  display:none;
}

.menu {
  // z-index: 2000;
  visibility: visible;
  opacity: 1;
}

.home {
  .menu-toggle {
    display:block;
  }

  .menu {
    // z-index: 2000;
    visibility: hidden;
    opacity: 0;
  }
}

.overlay-open {
  header.header {
    top: 0;
    transition: none;
  }

  .menu {
    // z-index: 2000;
    visibility: visible;
    opacity: 1;
  }
}

// Show view
.navigation-open, .overlay-open {
  .menu-toggle {
    visibility: hidden;
    // z-index: 99999;
    opacity: 0;
  }

  .menu {
    visibility: visible;
    // z-index: 99999;
    opacity: 1;
  }
}


//http://stackoverflow.com/a/20249560
ul.nav {
  a::after {
      display: block;
      content: attr(data-title);
      font-weight: bold;
      height: 1px;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
      margin-bottom: -1px;
  }
}

ul.nav {
  li.active {
    font-weight: bold;
  }

  // Highlight logic
  &:hover {
    li:hover {
      font-weight: bold;
    }
    li:not(:hover) {
      font-weight: normal !important;
    }
  }
}


header.header {
  top: 0;
}

footer.header {
  bottom: 0;
}

body.home {
  header.header {
    top: -3rem;
  }

  footer.header {
    bottom: -3rem;
  }


  &.navbars-toggled {

    header.header {
      transition: top .25s,
    }

    footer.header {
      transition: bottom .25s,
    }

  }

  &.navbars-toggled {
    header.header {
      top: 0;
    }

    footer.header {
      bottom: 0;
    }
  }
}
