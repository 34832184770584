/**
 * Preloader
 */

// Animation
@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.preloader {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 100%;

  opacity: 1;
  background: white;
}

.preloader:after {
  content: '';

  position: absolute;
  // top: 50%;
  // left: 50%;

  // width: 1.5em;
  // height: 1.5em;

  width: 100%;
  height: 100%;

  // transform: translate(-50%, -50%);
  // transform-origin: top left;
  // animation: flash 1s infinite ease;
  animation: flash 4s infinite ease;
      // animation-timing-function:steps(1, end);


  // border-radius: 50%;
  background-color: black;
}

.preloader.preloader--pinned:after {
  visibility: hidden;

  // background-color: white;


  animation: none;
}


.pin-indicator.flash {
  animation: flash 4s infinite ease;
}

.pin-indicator.hidden {
  opacity: 0!important;
}
