/**
 * Meta field hover
 */

.meta-field {
  display: block;
}

.meta-field-label {
  margin-top: 1.25rem;
  // font-weight: normal;
}

.nowrap {
  .meta-field {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.meta-field.meta-field--visible-label {
  &:before {
    content: attr(data-meta-field-label) ':';
    display: block;
  }
}

.single, #js-main {
  .meta-field {
    margin-bottom: 1.25em;
  }
}

article {
  header {
    * {
      font-weight: bold;
    }
  }
}

.panel {
  article {
    header {
      * {
        font-weight: normal;
      }
    }
  }
}

// Toggle button
.free-text-toggle {
  position: absolute; bottom: 0; right: 0;
}

.free-text {
  display: none;
}

// Show meta field label on mouse over once navbars are toggled
.navbars-toggled {
  .nowrap {
    .meta-field {
      &.highlight, &:hover {
        &:before {
          content: attr(data-meta-field-label); // http://stackoverflow.com/a/5734583
          position: absolute;
          background: white;
          font-weight: bold;
          width: 100%;
          overflow: hidden;
          opacity: 1;
        }
      }
    }
  }
}
