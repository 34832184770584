
/**
 * Search
 */
.searchform {
  @extend .h2;
}

.searchform {
  input {
    width: 100%;
    height: 100%;
    border: 0;
    text-align: center;
    background:transparent;
    &:focus {
      border: 0;
      outline:none;
    }
  }
}

:focus::placeholder {
    opacity: 0;
}

::placeholder {
  // color:gray;
}

// Disable search
.js-search-toggle {
  font-weight: normal!important;
  pointer-events: none;
}
