// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;

$brand-primary:             #000000 !default;
$brand-success:             #5cb85c !default;
$brand-info:                #5bc0de !default;
$brand-warning:             #f0ad4e !default;
$brand-danger:              #d9534f !default;
$brand-inverse:             $gray-dark !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               false;
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        false;
$enable-hover-media-query:  false;
$enable-grid-classes:       true;
$enable-print-styles:       false;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   .75rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: $spacer-x,
    y: $spacer-y
  ),
  2: (
    x: ($spacer-x * 2),
    y: ($spacer-y * 2)
  ),
  3: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  4: (
    x: ($spacer-x * 4),
    y: ($spacer-y * 4)
  )

) !default;
$border-width: 1px !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:    #fff !default;
$body-color: #000;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%);
$link-hover-decoration: none;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:      12 !default;
$grid-gutter-width: 30px !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root: 12px !default;

$font-size-base: 1rem !default;
$font-size-lg:   1.25rem !default;
$font-size-sm:   .8rem !default;
$font-size-xs:   .64rem !default;

$line-height-base: 1.25 !default;

$font-size-h1: 1rem !default;
// $font-size-h2: 1.5rem !default;
$font-size-h2: 1rem !default;
$font-size-h3: 1rem !default;
$font-size-h4: 1rem !default;
$font-size-h5: 1rem !default;
$font-size-h6: 1rem !default;

// $display1-size: 6rem !default;
// $display2-size: 5.5rem !default;
// $display3-size: 4.5rem !default;
// $display4-size: 3.5rem !default;

// $display1-weight: 300 !default;
// $display2-weight: 300 !default;
// $display3-weight: 300 !default;
// $display4-weight: 300 !default;

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
// $headings-line-height:   1.1 !default;
$headings-line-height:   $line-height-base !default;
$headings-color:         inherit !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

// $text-muted: $gray-light !default;

// $abbr-border-color: $gray-light !default;

// $blockquote-small-color:  $gray-light !default;
// $blockquote-font-size:    ($font-size-base * 1.25) !default;
// $blockquote-border-color: $gray-lighter !default;
// $blockquote-border-width: .25rem !default;

// $hr-border-color: rgba(0,0,0,.1) !default;
// $hr-border-width: $border-width !default;

// $mark-padding: .2em !default;

// $dt-font-weight: bold !default;

// $kbd-box-shadow:         inset 0 -.1rem 0 rgba(0,0,0,.25) !default;
// $nested-kbd-font-weight: bold !default;

// $list-inline-padding: 5px !default;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         (4 / 3) !default;
$line-height-sm:         1.5 !default;

// $border-radius:          .25rem !default;
// $border-radius-lg:       .3rem !default;
// $border-radius-sm:       .2rem !default;

// $component-active-color: #fff !default;
// $component-active-bg:    $brand-primary !default;

// $caret-width:            .3em !default;
// $caret-width-lg:         $caret-width !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown-backdrop:  990 !default;
$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      999999999 !default;
$zindex-navbar-sticky:     1030 !default;
$zindex-modal-bg:          1040 !default;
$zindex-modal:             1050 !default;


// Navbar

$navbar-padding-x:                  0;
$navbar-padding-y:                  $spacer;
