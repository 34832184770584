/**
 * Overlay
 */

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  overflow: hidden;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0s, opacity 0s;
}


body.overlay-open {
  overflow:hidden;
}

.overlay-open {
  .footer-inner--standard {
    display: none;
  }

  .footer-inner--overlay {
    display: block;
  }

  .feed-footer {
    opacity: 0;
    visibility: hidden;
  }

  .main-navigation {
    display: block!important;
  }
}

.overlay-open .overlay {
    overflow-x: hidden;
    overflow-y: scroll;
}

.overlay.js-open {
  visibility: visible;
  opacity: 1;
}

// Footern som syns i overlay - byt namn.
.footer-inner--overlay {
  display: none;
}
