/**
 * Scrollbar
 */
/* Turn on custom 8px wide scrollbar */
// ::-webkit-scrollbar {
//   width: 10px; /* 1px wider than Lion. */
//   /* This is more usable for users trying to click it. */
//   background-color: rgba(0,0,0,0);
//   -webkit-border-radius: 100px;
// }
// /* hover effect for both scrollbar area, and scrollbar 'thumb' */
// ::-webkit-scrollbar:hover {
//   // background-color: rgba(0, 0, 0, 0.09);
// }

// /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
// ::-webkit-scrollbar-thumb:vertical {
//   This is the EXACT color of Mac OS scrollbars.
//      Yes, I pulled out digital color meter
//   background: rgba(0,0,0,0.5);
//   -webkit-border-radius: 100px;
//   background-clip: padding-box;
//   border: 2px solid rgba(0, 0, 0, 0);
//   min-height: 10px; /*Prevent it from getting too small */
// }
// ::-webkit-scrollbar-thumb:vertical:active {
//   background: rgba(0,0,0,0.61); /* Some darker color when you click it */
//   -webkit-border-radius: 100px;
// }


/*mozilla scrolbalken*/

#left {
    // overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
}
// #left::-webkit-scrollbar {
//       // background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) repeat !important;
//       display: none;
// }


::-webkit-scrollbar {
  // background: transparent;
}
::-webkit-scrollbar-button {}
// ::-webkit-scrollbar-track {
//   background: transparent;
// }
// ::-webkit-scrollbar-track-piece {
//   background: transparent;
// }
::-webkit-scrollbar-thumb {

}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background:white;
}


// ::-webkit-scrollbar-track-piece:start,
// ::-webkit-scrollbar-track-piece:end {
//     background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=) repeat !important;
// }

// ::-webkit-scrollbar-track-piece:end {
//     background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==) repeat-y !important;
// }

// ::-webkit-scrollbar-thumb:window-inactive {
//   background:yellow;
// }

::-webkit-scrollbar-corner {}
::-webkit-resize {}

// ::-webkit-scrollbar {
//     background: transparent;
// }
// ::-webkit-scrollbar-thumb {
//     background-color: rgba(0, 0, 0, 0.2);
//     border: solid whiteSmoke 4px;
// }
// ::-webkit-scrollbar-thumb:hover {
//     background-color: rgba(0, 0, 0, 0.3);
// }
