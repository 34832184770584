/**
 * Filters
 */

.filter-group-header {
  margin-top: 2.5em;
  margin-bottom: 1.25em;
}

.button-group:first-child .filter-group-header {
  margin-top: 0;
}

.filters {
  // font-size: 0.8em;
  ul {
    list-style: none;
    padding: 0;
  }
}

ul.portfolio-list {
  list-style: none;
  padding-left: 0;
}


ul.button-group {
  margin-bottom: 1.25em;
}


.portfolio-item-title {
  // display: inline-block;
}

li.portfolio-item {
      text-indent: -1em;
    margin-left: 1em;
}


.portfolio-item {
  transition: opacity .125s;



}

.portfolio-list.filters-active {


  .portfolio-item{
    // opacity: .5
    // opacity: 0;
  }

  .portfolio-item.show{
    // opacity: 1;
        // text-decoration: underline;
        font-weight:bold;
  }

}

a[data-filter].active {
  // text-decoration:underline!important;

  font-weight: bold;
}


/**
 * Archive
 */
.archive-year {
  margin-top: 1.25em;
}

.archive-year:first-child {
  margin-top: 0;
}
