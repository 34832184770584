/**
 * Source: http://emilolsson.com/tools/vw-unit-calc-an-online-responsive-css-font-size-calculator/
 */
@function get-vw($context, $target) {
  $vw-context: ($context * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}


@function strip-units($value) {
    @return $value / ($value * 0 + 1);
}
