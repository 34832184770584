/**
 * Source: http://emilolsson.com/tools/vw-unit-calc-an-online-responsive-css-font-size-calculator/
 */
/**
 * Normalize.css
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/* HTML5 display definitions
       ========================================================================== */
/**
     * Add the correct display in IE <10.
     * Add the correct display in Edge, IE, and Firefox for `details` or `summary`.
     * Add the correct display in IE for `main`.
     */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
     * Add the correct display in IE <10.
     */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
     * Add the correct display and remove excess height in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  vertical-align: baseline; }

/**
     * Add the correct display in IE <11, Safari <8, and Firefox <22.
     * 1. Add the correct display in IE.
     */
template,
[hidden] {
  display: none; }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox <40.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/**
     * Add the correct font style in Android <4.4.
     */
dfn {
  font-style: italic; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 0.75em 0; }

/**
     * Add the correct background and color in IE <10.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Remove the border on images inside links in IE <11.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1.5em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
       ========================================================================== */
/**
     * Known issues:
     * - By default, Chrome on OS X and Safari on OS X allow very limited styling of
     *   select, unless a border property is set. The default font weight on
     *   optgroup elements cannot safely be changed in Chrome on OSX and Safari on
     *   OS X.
     * - It is recommended that you do not style checkbox and radio inputs as
     *   Firefox's implementation does not respect box-sizing, padding, or width.
     * - Certain font size values applied to number inputs cause the cursor style of
     *   the decrement button to change from default to text.
     * - The search input is not fully stylable by default. In Chrome and Safari on
     *   OSX/iOS you can't control font, padding, border, or background. In Chrome
     *   and Safari on Windows you can't control border properly. It will apply
     *   border-width but will only show a border color (which cannot be controlled)
     *   for the outer 1px of that border. Applying -webkit-appearance: textfield
     *   addresses these issues without removing the benefits of search inputs (e.g.
     *   showing past searches). Safari (but not Chrome) will clip the cancel button
     *   on when it has padding (and textfield appearance).
     */
/**
     * 1. Change font properties to `inherit` in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     * 3. Address `font-family` inconsistency between `textarea` and other form in IE 7
     * 4. Improve appearance and consistency with IE 6/7.
     */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE <11.
     * 2. Remove the padding in IE <11.
     * 3. Remove excess padding in IE 7.
     *    Known issue: excess padding remains in IE 6.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * Correct the text style of placeholders in Chrome, Edge, and Safari.
     */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     * 4. Correct alignment displayed oddly in IE 6/7.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
  color: inherit;
  /* 2 */
  padding: 0;
  /* 3 */ }

/**
     * Restore the font weight unset by a previous rule.
     */
optgroup {
  font-weight: bold; }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/**
 * Typey
 */
/**
 * Susy
 */
/**
 * Bootstrap
 */
/*!
 * Bootstrap v4.0.0-alpha.4 (http://getbootstrap.com)
 * Copyright 2011-2016 The Bootstrap Authors
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

html {
  /*** Fallback ***/
  font-size: 12px;
  /*** Responsive ***/
  font-size: 1.171875vw; }

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.25;
  color: #000;
  background-color: #fff; }

body {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

[tabindex="-1"]:focus {
  outline: none !important; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #818a91; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

a {
  color: #000000;
  text-decoration: none; }
  a:focus, a:hover {
    color: black;
    text-decoration: none; }
  a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: none; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle; }

[role="button"] {
  cursor: pointer; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse;
  background-color: transparent; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #818a91;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
textarea {
  margin: 0;
  line-height: inherit;
  border-radius: 0; }

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  cursor: not-allowed; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit; }

input[type="search"] {
  -webkit-appearance: none; }

output {
  display: inline-block; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .searchform, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.25;
  color: inherit; }

h1, .h1 {
  font-size: 1rem; }

h2, .h2, .searchform {
  font-size: 1rem; }

h3, .h3 {
  font-size: 1rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300; }

hr {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  border-left: 0.25rem solid #eceeef; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #818a91; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.blockquote-reverse {
  padding-right: 0.75rem;
  padding-left: 0;
  text-align: right;
  border-right: 0.25rem solid #eceeef;
  border-left: 0; }

.blockquote-reverse .blockquote-footer::before {
  content: ""; }

.blockquote-reverse .blockquote-footer::after {
  content: "\00A0 \2014"; }

dl.row > dd + dt {
  clear: left; }

.img-fluid, .wp-caption {
  display: block;
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  transition: all .2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.img-circle {
  border-radius: 50%; }

.figure {
  margin-bottom: 1.25rem; }

.figure-img, .wp-caption {
  width: auto !important;
  margin-bottom: 1.25rem; }

.figure-caption, .wp-caption-text {
  font-size: 0.8rem;
  margin-top: 0.375rem; }

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem; }
  .container::after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 544px) {
    .container {
      max-width: 576px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 940px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem; }
  .container-fluid::after {
    content: "";
    display: table;
    clear: both; }

.row {
  margin-left: -0.75rem;
  margin-right: -0.75rem; }
  .row::after {
    content: "";
    display: table;
    clear: both; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  min-height: 1px;
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.col-xs-1 {
  float: left;
  width: 8.3333333333%; }

.col-xs-2 {
  float: left;
  width: 16.6666666667%; }

.col-xs-3 {
  float: left;
  width: 25%; }

.col-xs-4 {
  float: left;
  width: 33.3333333333%; }

.col-xs-5 {
  float: left;
  width: 41.6666666667%; }

.col-xs-6 {
  float: left;
  width: 50%; }

.col-xs-7 {
  float: left;
  width: 58.3333333333%; }

.col-xs-8 {
  float: left;
  width: 66.6666666667%; }

.col-xs-9 {
  float: left;
  width: 75%; }

.col-xs-10 {
  float: left;
  width: 83.3333333333%; }

.col-xs-11 {
  float: left;
  width: 91.6666666667%; }

.col-xs-12 {
  float: left;
  width: 100%; }

.pull-xs-0 {
  right: auto; }

.pull-xs-1 {
  right: 8.3333333333%; }

.pull-xs-2 {
  right: 16.6666666667%; }

.pull-xs-3 {
  right: 25%; }

.pull-xs-4 {
  right: 33.3333333333%; }

.pull-xs-5 {
  right: 41.6666666667%; }

.pull-xs-6 {
  right: 50%; }

.pull-xs-7 {
  right: 58.3333333333%; }

.pull-xs-8 {
  right: 66.6666666667%; }

.pull-xs-9 {
  right: 75%; }

.pull-xs-10 {
  right: 83.3333333333%; }

.pull-xs-11 {
  right: 91.6666666667%; }

.pull-xs-12 {
  right: 100%; }

.push-xs-0 {
  left: auto; }

.push-xs-1 {
  left: 8.3333333333%; }

.push-xs-2 {
  left: 16.6666666667%; }

.push-xs-3 {
  left: 25%; }

.push-xs-4 {
  left: 33.3333333333%; }

.push-xs-5 {
  left: 41.6666666667%; }

.push-xs-6 {
  left: 50%; }

.push-xs-7 {
  left: 58.3333333333%; }

.push-xs-8 {
  left: 66.6666666667%; }

.push-xs-9 {
  left: 75%; }

.push-xs-10 {
  left: 83.3333333333%; }

.push-xs-11 {
  left: 91.6666666667%; }

.push-xs-12 {
  left: 100%; }

.offset-xs-1 {
  margin-left: 8.3333333333%; }

.offset-xs-2 {
  margin-left: 16.6666666667%; }

.offset-xs-3 {
  margin-left: 25%; }

.offset-xs-4 {
  margin-left: 33.3333333333%; }

.offset-xs-5 {
  margin-left: 41.6666666667%; }

.offset-xs-6 {
  margin-left: 50%; }

.offset-xs-7 {
  margin-left: 58.3333333333%; }

.offset-xs-8 {
  margin-left: 66.6666666667%; }

.offset-xs-9 {
  margin-left: 75%; }

.offset-xs-10 {
  margin-left: 83.3333333333%; }

.offset-xs-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 544px) {
  .col-sm-1 {
    float: left;
    width: 8.3333333333%; }
  .col-sm-2 {
    float: left;
    width: 16.6666666667%; }
  .col-sm-3 {
    float: left;
    width: 25%; }
  .col-sm-4 {
    float: left;
    width: 33.3333333333%; }
  .col-sm-5 {
    float: left;
    width: 41.6666666667%; }
  .col-sm-6 {
    float: left;
    width: 50%; }
  .col-sm-7 {
    float: left;
    width: 58.3333333333%; }
  .col-sm-8 {
    float: left;
    width: 66.6666666667%; }
  .col-sm-9 {
    float: left;
    width: 75%; }
  .col-sm-10 {
    float: left;
    width: 83.3333333333%; }
  .col-sm-11 {
    float: left;
    width: 91.6666666667%; }
  .col-sm-12 {
    float: left;
    width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.3333333333%; }
  .pull-sm-2 {
    right: 16.6666666667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.3333333333%; }
  .pull-sm-5 {
    right: 41.6666666667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.3333333333%; }
  .pull-sm-8 {
    right: 66.6666666667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.3333333333%; }
  .pull-sm-11 {
    right: 91.6666666667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.3333333333%; }
  .push-sm-2 {
    left: 16.6666666667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.3333333333%; }
  .push-sm-5 {
    left: 41.6666666667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.3333333333%; }
  .push-sm-8 {
    left: 66.6666666667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.3333333333%; }
  .push-sm-11 {
    left: 91.6666666667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 768px) {
  .col-md-1 {
    float: left;
    width: 8.3333333333%; }
  .col-md-2 {
    float: left;
    width: 16.6666666667%; }
  .col-md-3 {
    float: left;
    width: 25%; }
  .col-md-4 {
    float: left;
    width: 33.3333333333%; }
  .col-md-5 {
    float: left;
    width: 41.6666666667%; }
  .col-md-6 {
    float: left;
    width: 50%; }
  .col-md-7 {
    float: left;
    width: 58.3333333333%; }
  .col-md-8 {
    float: left;
    width: 66.6666666667%; }
  .col-md-9 {
    float: left;
    width: 75%; }
  .col-md-10 {
    float: left;
    width: 83.3333333333%; }
  .col-md-11 {
    float: left;
    width: 91.6666666667%; }
  .col-md-12 {
    float: left;
    width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.3333333333%; }
  .pull-md-2 {
    right: 16.6666666667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.3333333333%; }
  .pull-md-5 {
    right: 41.6666666667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.3333333333%; }
  .pull-md-8 {
    right: 66.6666666667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.3333333333%; }
  .pull-md-11 {
    right: 91.6666666667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.3333333333%; }
  .push-md-2 {
    left: 16.6666666667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.3333333333%; }
  .push-md-5 {
    left: 41.6666666667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.3333333333%; }
  .push-md-8 {
    left: 66.6666666667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.3333333333%; }
  .push-md-11 {
    left: 91.6666666667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0%; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-lg-1 {
    float: left;
    width: 8.3333333333%; }
  .col-lg-2 {
    float: left;
    width: 16.6666666667%; }
  .col-lg-3 {
    float: left;
    width: 25%; }
  .col-lg-4 {
    float: left;
    width: 33.3333333333%; }
  .col-lg-5 {
    float: left;
    width: 41.6666666667%; }
  .col-lg-6 {
    float: left;
    width: 50%; }
  .col-lg-7 {
    float: left;
    width: 58.3333333333%; }
  .col-lg-8 {
    float: left;
    width: 66.6666666667%; }
  .col-lg-9 {
    float: left;
    width: 75%; }
  .col-lg-10 {
    float: left;
    width: 83.3333333333%; }
  .col-lg-11 {
    float: left;
    width: 91.6666666667%; }
  .col-lg-12 {
    float: left;
    width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.3333333333%; }
  .pull-lg-2 {
    right: 16.6666666667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.3333333333%; }
  .pull-lg-5 {
    right: 41.6666666667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.3333333333%; }
  .pull-lg-8 {
    right: 66.6666666667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.3333333333%; }
  .pull-lg-11 {
    right: 91.6666666667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.3333333333%; }
  .push-lg-2 {
    left: 16.6666666667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.3333333333%; }
  .push-lg-5 {
    left: 41.6666666667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.3333333333%; }
  .push-lg-8 {
    left: 66.6666666667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.3333333333%; }
  .push-lg-11 {
    left: 91.6666666667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1200px) {
  .col-xl-1 {
    float: left;
    width: 8.3333333333%; }
  .col-xl-2 {
    float: left;
    width: 16.6666666667%; }
  .col-xl-3 {
    float: left;
    width: 25%; }
  .col-xl-4 {
    float: left;
    width: 33.3333333333%; }
  .col-xl-5 {
    float: left;
    width: 41.6666666667%; }
  .col-xl-6 {
    float: left;
    width: 50%; }
  .col-xl-7 {
    float: left;
    width: 58.3333333333%; }
  .col-xl-8 {
    float: left;
    width: 66.6666666667%; }
  .col-xl-9 {
    float: left;
    width: 75%; }
  .col-xl-10 {
    float: left;
    width: 83.3333333333%; }
  .col-xl-11 {
    float: left;
    width: 91.6666666667%; }
  .col-xl-12 {
    float: left;
    width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.3333333333%; }
  .pull-xl-2 {
    right: 16.6666666667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.3333333333%; }
  .pull-xl-5 {
    right: 41.6666666667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.3333333333%; }
  .pull-xl-8 {
    right: 66.6666666667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.3333333333%; }
  .pull-xl-11 {
    right: 91.6666666667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.3333333333%; }
  .push-xl-2 {
    left: 16.6666666667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.3333333333%; }
  .push-xl-5 {
    left: 41.6666666667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.3333333333%; }
  .push-xl-8 {
    left: 66.6666666667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.3333333333%; }
  .push-xl-11 {
    left: 91.6666666667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-1 {
    margin-left: 8.3333333333%; }
  .offset-xl-2 {
    margin-left: 16.6666666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.3333333333%; }
  .offset-xl-5 {
    margin-left: 41.6666666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.3333333333%; }
  .offset-xl-8 {
    margin-left: 66.6666666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.3333333333%; }
  .offset-xl-11 {
    margin-left: 91.6666666667%; } }

.bg-faded {
  background-color: #f7f7f9; }

.bg-primary {
  color: #fff !important;
  background-color: #000000 !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: black !important; }

.bg-success {
  color: #fff !important;
  background-color: #5cb85c !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #449d44 !important; }

.bg-info {
  color: #fff !important;
  background-color: #5bc0de !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #31b0d5 !important; }

.bg-warning {
  color: #fff !important;
  background-color: #f0ad4e !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ec971f !important; }

.bg-danger {
  color: #fff !important;
  background-color: #d9534f !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #c9302c !important; }

.bg-inverse {
  color: #fff !important;
  background-color: #373a3c !important; }

a.bg-inverse:focus, a.bg-inverse:hover {
  background-color: #1f2021 !important; }

.clearfix::after {
  content: "";
  display: table;
  clear: both; }

.d-block {
  display: block !important; }

.d-inline-block {
  display: inline-block !important; }

.d-inline {
  display: inline !important; }

.pull-xs-left {
  float: left !important; }

.pull-xs-right {
  float: right !important; }

.pull-xs-none {
  float: none !important; }

@media (min-width: 544px) {
  .pull-sm-left {
    float: left !important; }
  .pull-sm-right {
    float: right !important; }
  .pull-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .pull-md-left {
    float: left !important; }
  .pull-md-right {
    float: right !important; }
  .pull-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .pull-lg-left {
    float: left !important; }
  .pull-lg-right {
    float: right !important; }
  .pull-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .pull-xl-left {
    float: left !important; }
  .pull-xl-right {
    float: right !important; }
  .pull-xl-none {
    float: none !important; } }

.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .screen-reader-text:active, .sr-only-focusable:focus, .screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

.w-100 {
  width: 100% !important; }

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.m-a-0 {
  margin: 0 0 !important; }

.m-t-0 {
  margin-top: 0 !important; }

.m-r-0 {
  margin-right: 0 !important; }

.m-b-0 {
  margin-bottom: 0 !important; }

.m-l-0 {
  margin-left: 0 !important; }

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-a-1 {
  margin: 0.75rem 0.75rem !important; }

.m-t-1 {
  margin-top: 0.75rem !important; }

.m-r-1 {
  margin-right: 0.75rem !important; }

.m-b-1 {
  margin-bottom: 0.75rem !important; }

.m-l-1 {
  margin-left: 0.75rem !important; }

.m-x-1 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important; }

.m-y-1 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }

.m-a-2 {
  margin: 1.5rem 1.5rem !important; }

.m-t-2 {
  margin-top: 1.5rem !important; }

.m-r-2 {
  margin-right: 1.5rem !important; }

.m-b-2 {
  margin-bottom: 1.5rem !important; }

.m-l-2 {
  margin-left: 1.5rem !important; }

.m-x-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.m-y-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-a-3 {
  margin: 2.25rem 2.25rem !important; }

.m-t-3 {
  margin-top: 2.25rem !important; }

.m-r-3 {
  margin-right: 2.25rem !important; }

.m-b-3 {
  margin-bottom: 2.25rem !important; }

.m-l-3 {
  margin-left: 2.25rem !important; }

.m-x-3 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important; }

.m-y-3 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important; }

.m-a-4 {
  margin: 3rem 3rem !important; }

.m-t-4 {
  margin-top: 3rem !important; }

.m-r-4 {
  margin-right: 3rem !important; }

.m-b-4 {
  margin-bottom: 3rem !important; }

.m-l-4 {
  margin-left: 3rem !important; }

.m-x-4 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.m-y-4 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.p-a-0 {
  padding: 0 0 !important; }

.p-t-0 {
  padding-top: 0 !important; }

.p-r-0 {
  padding-right: 0 !important; }

.p-b-0 {
  padding-bottom: 0 !important; }

.p-l-0 {
  padding-left: 0 !important; }

.p-x-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-a-1 {
  padding: 0.75rem 0.75rem !important; }

.p-t-1 {
  padding-top: 0.75rem !important; }

.p-r-1 {
  padding-right: 0.75rem !important; }

.p-b-1 {
  padding-bottom: 0.75rem !important; }

.p-l-1 {
  padding-left: 0.75rem !important; }

.p-x-1 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important; }

.p-y-1 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }

.p-a-2 {
  padding: 1.5rem 1.5rem !important; }

.p-t-2 {
  padding-top: 1.5rem !important; }

.p-r-2 {
  padding-right: 1.5rem !important; }

.p-b-2 {
  padding-bottom: 1.5rem !important; }

.p-l-2 {
  padding-left: 1.5rem !important; }

.p-x-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.p-y-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-a-3 {
  padding: 2.25rem 2.25rem !important; }

.p-t-3 {
  padding-top: 2.25rem !important; }

.p-r-3 {
  padding-right: 2.25rem !important; }

.p-b-3 {
  padding-bottom: 2.25rem !important; }

.p-l-3 {
  padding-left: 2.25rem !important; }

.p-x-3 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important; }

.p-y-3 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important; }

.p-a-4 {
  padding: 3rem 3rem !important; }

.p-t-4 {
  padding-top: 3rem !important; }

.p-r-4 {
  padding-right: 3rem !important; }

.p-b-4 {
  padding-bottom: 3rem !important; }

.p-l-4 {
  padding-left: 3rem !important; }

.p-x-4 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.p-y-4 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999999999; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-xs-left {
  text-align: left !important; }

.text-xs-right {
  text-align: right !important; }

.text-xs-center {
  text-align: center !important; }

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-muted {
  color: #818a91 !important; }

a.text-muted:focus, a.text-muted:hover {
  color: #687077 !important; }

.text-primary {
  color: #000000 !important; }

a.text-primary:focus, a.text-primary:hover {
  color: black !important; }

.text-success {
  color: #5cb85c !important; }

a.text-success:focus, a.text-success:hover {
  color: #449d44 !important; }

.text-info {
  color: #5bc0de !important; }

a.text-info:focus, a.text-info:hover {
  color: #31b0d5 !important; }

.text-warning {
  color: #f0ad4e !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #ec971f !important; }

.text-danger {
  color: #d9534f !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #c9302c !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.invisible {
  visibility: hidden !important; }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 543px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 544px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

/**
 * Components
 */
/**
 * Preloader
 */
@keyframes flash {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.preloader {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: white; }

.preloader:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  animation: flash 4s infinite ease;
  background-color: black; }

.preloader.preloader--pinned:after {
  visibility: hidden;
  animation: none; }

.pin-indicator.flash {
  animation: flash 4s infinite ease; }

.pin-indicator.hidden {
  opacity: 0 !important; }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 0.375rem auto;
  height: auto; }

.alignleft,
.alignright {
  margin-bottom: 0.375rem;
  height: auto; }

@media (min-width: 544px) {
  .alignleft {
    float: left;
    margin-right: 0.375rem; }
  .alignright {
    float: right;
    margin-left: 0.375rem; } }

@keyframes pulse {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/**
 * -----------------------------------------------------------------------------
 */
html, body {
  margin: 0;
  padding: 0;
  overflow: auto; }

.wrap {
  min-height: 100vh; }

.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .row.no-gutters > [class^="col-"],
  .row.no-gutters > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0; }

.contributor-test {
  display: block; }

p + .contributor-test {
  margin-top: 2.5em; }

/**
 * Push scrollbars to stay on top of position: fixed content
 * Tested in Chrome
 */
.panels::after {
  content: "";
  position: absolute;
  white-space: nowrap;
  pointer-events: none;
  z-index: 9999; }

/**
 * Front Page Sections
 */
#left, #right {
  backface-visibility: hidden;
  will-change: scroll-position;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: white;
  position: absolute; }

#left {
  left: 0; }

#right {
  right: 0; }

/**
 * Panels
 */
.panel {
  position: relative;
  transform: translate3d(0, 0, 0); }

/** Vertically centered container */
.panel .container-valigned {
  position: absolute;
  display: block;
  text-align: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.panel.spacer {
  position: relative;
  width: 50vw; }

/**
 * Scroll Sections
 */
.scroll-section {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 3rem;
  bottom: 3rem; }

.scroll-section-left {
  left: 0; }

.scroll-section-right {
  right: 0; }

/**
 * Utils
 */
.p-e-none {
  pointer-events: none; }

.p-t-5 {
  padding-top: 3.5rem !important; }

/**
 * ----------------------------------------------------------------------------
 */
.js-highlight-sibling img {
  filter: grayscale(100%);
  opacity: 0.8; }

.col-xs-f-none {
  float: none; }

/**
 * Feed Footer
 */
.feed-footer {
  z-index: 6;
  position: fixed;
  bottom: -3rem;
  width: 100%;
  height: 3rem;
  background: white;
  transition: bottom .25s; }
  .feed-footer .col-xs-12 {
    height: inherit; }

body.footer-toggled .feed-footer {
  bottom: 0; }

/**
 * Meta field hover
 */
.meta-field {
  display: block; }

.meta-field-label {
  margin-top: 1.25rem; }

.nowrap .meta-field {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.meta-field.meta-field--visible-label:before {
  content: attr(data-meta-field-label) ":";
  display: block; }

.single .meta-field, #js-main .meta-field {
  margin-bottom: 1.25em; }

article header * {
  font-weight: bold; }

.panel article header * {
  font-weight: normal; }

.free-text-toggle {
  position: absolute;
  bottom: 0;
  right: 0; }

.free-text {
  display: none; }

.navbars-toggled .nowrap .meta-field.highlight:before, .navbars-toggled .nowrap .meta-field:hover:before {
  content: attr(data-meta-field-label);
  position: absolute;
  background: white;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  opacity: 1; }

/**
 * Front page panel
 */
.masthead {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 3; }

.masthead > div {
  height: 100%; }

.masthead .masthead-title {
  width: 100%;
  text-align: center;
  font-size: 8vw;
  font-weight: bold;
  line-height: 1.1;
  position: absolute;
  margin: 0;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

/**
 * Search
 */
.searchform input {
  width: 100%;
  height: 100%;
  border: 0;
  text-align: center;
  background: transparent; }
  .searchform input:focus {
    border: 0;
    outline: none; }

:focus::placeholder {
  opacity: 0; }

.js-search-toggle {
  font-weight: normal !important;
  pointer-events: none; }

/**
 * Overlay
 */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  overflow: hidden;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0s, opacity 0s; }

body.overlay-open {
  overflow: hidden; }

.overlay-open .footer-inner--standard {
  display: none; }

.overlay-open .footer-inner--overlay {
  display: block; }

.overlay-open .feed-footer {
  opacity: 0;
  visibility: hidden; }

.overlay-open .main-navigation {
  display: block !important; }

.overlay-open .overlay {
  overflow-x: hidden;
  overflow-y: scroll; }

.overlay.js-open {
  visibility: visible;
  opacity: 1; }

.footer-inner--overlay {
  display: none; }

/**
 * Navigation
 */
footer.main-navigation {
  transition: bottom 1s; }

.navigation {
  z-index: 4000;
  user-select: none; }

.navigation {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.main-navigation li a {
  /* lift, to allow :before be injected below and to use this as position reference */
  position: relative; }
  .main-navigation li a:before {
    content: "";
    /* tuch area increase */
    left: -1em;
    right: -1em;
    bottom: -1em;
    top: -1em;
    /* position below button */
    position: absolute;
    z-index: -1; }

.navbar {
  padding: .75rem 0; }

.navbar-fixed {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 5; }

.close {
  animation: flash 4s infinite ease;
  animation-fill-mode: forwards; }
  .close:hover {
    animation: none;
    animation-fill-mode: forwards; }

.main-navigation {
  height: 3rem;
  transition: background-color .125s; }

.main-navigation {
  background-color: white; }

.main-navigation:hover {
  background-color: white; }

.main-navigation ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center; }
  .main-navigation ul li {
    display: inline-block;
    margin: 0 auto; }

.main-navigation .menu {
  width: 100%;
  top: 0; }
  .main-navigation .menu li {
    padding: 0 .5em; }

.menu-toggle {
  position: relative; }

.menu-toggle {
  z-index: -1; }

.menu-toggle {
  display: none; }

.menu {
  visibility: visible;
  opacity: 1; }

.home .menu-toggle {
  display: block; }

.home .menu {
  visibility: hidden;
  opacity: 0; }

.overlay-open header.header {
  top: 0;
  transition: none; }

.overlay-open .menu {
  visibility: visible;
  opacity: 1; }

.navigation-open .menu-toggle, .overlay-open .menu-toggle {
  visibility: hidden;
  opacity: 0; }

.navigation-open .menu, .overlay-open .menu {
  visibility: visible;
  opacity: 1; }

ul.nav a::after {
  display: block;
  content: attr(data-title);
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
  margin-bottom: -1px; }

ul.nav li.active {
  font-weight: bold; }

ul.nav:hover li:hover {
  font-weight: bold; }

ul.nav:hover li:not(:hover) {
  font-weight: normal !important; }

header.header {
  top: 0; }

footer.header {
  bottom: 0; }

body.home header.header {
  top: -3rem; }

body.home footer.header {
  bottom: -3rem; }

body.home.navbars-toggled header.header {
  transition: top 0.25s; }

body.home.navbars-toggled footer.header {
  transition: bottom 0.25s; }

body.home.navbars-toggled header.header {
  top: 0; }

body.home.navbars-toggled footer.header {
  bottom: 0; }

/**
 * Pagination
 */
.pagination .nav-links > * {
  padding-right: 1em; }

.navigation.pagination h2,
.navigation.post-navigation h2 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.page-numbers.current {
  font-weight: bold; }

.navigation.post-navigation .nav-links div {
  float: left; }

.nav-links div {
  padding-right: 1em; }

.entry-featured-image {
  height: calc(21.5em);
  overflow: hidden; }
  .entry-featured-image .image {
    width: 100%;
    height: 100%; }
  .entry-featured-image figure {
    height: 100%; }

.entry-featured-image.overflowing:after {
  content: "";
  position: absolute;
  /*top: calc(1.1em * ( 6 + (2 / 4) ) - 1.1em );*/
  top: 36.36rem;
  left: 0;
  height: 1.1em;
  width: 100%;
  /*border-top: .125em dotted gray;*/
  /*background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 50%, white);*/
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  z-index: 9999999; }

/**
 * Filters
 */
.filter-group-header {
  margin-top: 2.5em;
  margin-bottom: 1.25em; }

.button-group:first-child .filter-group-header {
  margin-top: 0; }

.filters ul {
  list-style: none;
  padding: 0; }

ul.portfolio-list {
  list-style: none;
  padding-left: 0; }

ul.button-group {
  margin-bottom: 1.25em; }

li.portfolio-item {
  text-indent: -1em;
  margin-left: 1em; }

.portfolio-item {
  transition: opacity .125s; }

.portfolio-list.filters-active .portfolio-item.show {
  font-weight: bold; }

a[data-filter].active {
  font-weight: bold; }

/**
 * Archive
 */
.archive-year {
  margin-top: 1.25em; }

.archive-year:first-child {
  margin-top: 0; }

/**
 * Pin indictator
 */
.pin-indicator {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 25%;
  height: .5em;
  transform: translate(-50%, -50%);
  background: black;
  z-index: 999999;
  opacity: 0; }
  .pin-indicator.active {
    opacity: 1; }

/**
 * ----------------------------------------------------------------------------
 */
/**
 * Entry classes
 */
.entry-content > :last-child {
  margin-bottom: 0; }

.panel article header h2,
.panel article header .entry-meta {
  font-size: 1.5625rem; }

h2.entry-title {
  margin-bottom: 0 !important; }

.entry-terms {
  margin-top: 1.25rem;
  font-size: 0.8rem; }

/**
 * ----------------------------------------------------------------------------
 */
figure {
  margin: 0; }

img {
  width: 100%;
  height: auto; }

/**
 * Scrollbar
 */
/* Turn on custom 8px wide scrollbar */
/*mozilla scrolbalken*/
#left {
  -ms-overflow-style: none; }
  #left::-webkit-scrollbar {
    display: none; }

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background: white; }
