/**
 * Pagination
 */
.pagination {
  .nav-links > * {
      padding-right: 1em;
  }
}
.navigation.pagination,
.navigation.post-navigation {
  h2 {
    @include sr-only();
  }
}

.page-numbers.current {
  font-weight: bold;
}

.navigation.post-navigation {
  .nav-links {
    div {
      float: left;
    }
  }
}

.nav-links {
  div {
    padding-right: 1em;
  }
}
