@import "common/variables";
@import "common/mixins";
@import "common/functions";

/**
 * Normalize.css
 */
@import "common/normalize";
@include normalize();

/**
 * Typey
 */
// @import "../bower_components/typey/stylesheets/typey";

/**
 * Susy
 */
// @import "bower_components/susy/sass/susy";
// @import "components/grid";

/**
 * Bootstrap
 */
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns:      12;
$grid-gutter-width: 1.5rem;
@import "common/bootstrap";

/**
 * Components
 */
@import "components/preloader";
@import "components/wp-classes";
@import "components/animations";




/**
 * -----------------------------------------------------------------------------
 */

html, body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

.wrap {
  min-height: 100vh;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > [class^="col-"],
  > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
}

.contributor-test {
  display: block;
}

p + .contributor-test {
  margin-top: 2.5em;
}

/**
 * Push scrollbars to stay on top of position: fixed content
 * Tested in Chrome
 */
.panels {
  &::after {
    content: "";
    position: absolute;
    // overflow: visible;
    white-space: nowrap;
    pointer-events: none;
    z-index: 9999;
  }
}

/**
 * Front Page Sections
 */
#left, #right {
  backface-visibility: hidden;
  will-change: scroll-position;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: white;
  position: absolute;
}

#left {
  left: 0;
}

#right {
  right: 0;
}

/**
 * Panels
 */
.panel {
  position: relative;
  transform: translate3d(0,0,0);
}

// Disable interaction until navbar is triggered
// .panel {
//   article {
//     pointer-events: none;
//   }
// }

// .navbars-toggled {
//   .panel {
//     article {
//       pointer-events: auto;
//     }
//   }
// }

/** Vertically centered container */
.panel .container-valigned {
    position: absolute;
    display: block;
    // margin: 0 auto;
    text-align: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.panel.spacer {
  position: relative;
  width: 50vw;
}

/**
 * Scroll Sections
 */
.scroll-section {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 3rem;
  bottom: 3rem;
}

.scroll-section-left {
  left: 0;
}

.scroll-section-right {
  right: 0;
}

/**
 * Utils
 */
.p-e-none {
  pointer-events: none;
}

.p-t-5 {
  padding-top: 3.5rem!important;
}

/**
 * ----------------------------------------------------------------------------
 */



.js-highlight-sibling {
  img {
    filter: grayscale(100%);
    opacity: 0.8;
  }
}

@include media-breakpoint-up(xs) {
  .col-xs-f-none {
    float: none;
  }
}





@import "components/unsorted/feed-footer";

@import "components/unsorted/meta-fields";

@import "components/unsorted/masthead";

@import "components/unsorted/search";

@import "components/unsorted/overlay";

@import "components/unsorted/navigation";

@import "components/unsorted/pagination";

@import "components/unsorted/post-thumbnail";

@import "components/unsorted/filter";

@import "components/unsorted/pin-indicator";

/**
 * ----------------------------------------------------------------------------
 */

/**
 * Entry classes
 */
.entry-content {
  > :last-child {
    margin-bottom: 0;
  }
}

.panel {
  article {
    header {
      h2,
      .entry-meta {
        font-size: 1.5625rem;
      }
    }
  }
}

h2.entry-title {
  margin-bottom: 0!important;
}

.entry-terms {
  margin-top: 1.25rem;
  font-size: 0.8rem;
}

/**
 * ----------------------------------------------------------------------------
 */

figure {
  margin: 0;
}

img {
  width: 100%;
  height: auto;
}





@import "components/scrollbar";


