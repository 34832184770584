/**
 * Feed Footer
 */

.feed-footer {
  z-index: 6;
  position: fixed;
  bottom: -3rem;
  // left: 0;
  width: 100%;
  height: 3rem;
  background: white;

  // transition: height 1s;

  transition: bottom .25s;

  // filter: invert(1);

  .col-xs-12 {
    height:inherit;
  }
}

body.footer-toggled {
  .feed-footer {
    bottom: 0;
  }
}
