.entry-featured-image {
  height: calc(21.5em);
  overflow: hidden;

  .image {
    width: 100%;
    height: 100%;
  }

  figure {
    height: 100%;
    // background: rgba(0,0,0,0.0625);
  }
}

.entry-featured-image.overflowing:after {
    content:"";
    position:absolute;
    /*top: calc(1.1em * ( 6 + (2 / 4) ) - 1.1em );*/
    top: 36.36rem;
    left:0;
    height: 1.1em;
    width: 100%;
    /*border-top: .125em dotted gray;*/

    /*background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 50%, white);*/
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
    z-index:9999999;
}
